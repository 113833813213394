import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

import villageRouter from './modules/village'
import recruitment from './modules/recruitment'
import partymember from './modules/partymember'
import wangge from './modules/wangge'
import pension from './modules/pension'
import guanghe from './modules/guanghe'
import fangyi from './modules/fangyi'
import huayang from './modules/huayang'
import zhengxie from './modules/zhengxie'
import oldman from './modules/oldman'
import anLuoYun from './modules/anLuoYun'
import Lycal from './modules/Lycal'
import ylOrder from './modules/ylOrder'

/* Layout */
import Layout from '@/layout'

const router = new Router({
  routes: [{
    path: '/',
    component: Layout,
    children: [{
      path: '',
      name: 'Home',
      meta: {
        title: "掌上服务"
      },
      component: () =>
        import('@/views/home/index.vue')
    },]
  },
  {
    path: '/test',
    component: Layout,
    children: [{
      path: '',
      meta: {
        title: "测试list"
      },
      component: () =>
        import('@/views/list.vue')
    },]
  },
  // 网上办事
  {
    path: '/work',
    component: Layout,
    children: [{
      name: 'WorkGroup',
      path: '',
      component: () =>
        import('@/views/work/group.vue')
    },
    {
      name: 'Work',
      path: ':nwkId',
      component: () =>
        import('@/views/work/index.vue')
    },
    {
      name: 'WorkApply',
      path: 'apply/:NWMId/:WorkKind',
      component: () =>
        import("@/views/work/apply/index.vue")
    }
    ]
  },
  // 社区活动
  {
    path: '/activity',
    component: Layout,
    children: [{
      path: '',
      meta: {
        title: "社区活动"
      },
      component: () =>
        import('@/views/activity/index.vue')
    },
    {
      path: 'detail/:Id',
      meta: {
        title: "社区活动"
      },
      component: () =>
        import('@/views/activity/detail.vue')
    },
    ]
  },
  // 我的活动记录
  {
    path: '/activityLog',
    component: Layout,
    children: [{
      path: '',
      meta: {
        title: "我的活动记录"
      },
      component: () =>
        import('@/views/activityLog/index.vue')
    },
    {
      path: 'detail/:Id',
      meta: {
        title: "我的活动记录"
      },
      component: () =>
        import('@/views/activityLog/detail.vue')
    },
    ]
  },
  // 资讯中心
  {
    path: '/article',
    component: Layout,
    children: [{
      path: '',
      meta: {
        title: "资讯中心"
      },
      component: () =>
        import('@/views/article/index.vue')
    },
    {
      path: 'detail/:Id',
      meta: {
        title: "资讯中心"
      },
      component: () =>
        import('@/views/article/detail.vue')
    },
    ]
  },
  // 社区风采
  {
    path: '/scenery',
    component: Layout,
    children: [{
      path: '',
      meta: {
        title: "社区风采"
      },
      component: () =>
        import('@/views/scenery/index.vue')
    },
    {
      path: 'detail/:Id',
      meta: {
        title: "社区风采"
      },
      component: () =>
        import('@/views/scenery/detail.vue')
    },
    ]
  },
  // 便民商店
  {
    path: '/shop',
    component: Layout,
    children: [{
      path: '',
      meta: {
        title: "便民商店"
      },
      component: () =>
        import('@/views/shop/index.vue')
    }]
  },
  // 个人中心
  {
    path: '/center',
    component: Layout,
    children: [{
      path: '',
      component: () =>
        import('@/views/center/index.vue')
    }]
  },
  // 通知公告
  {
    path: '/notice',
    component: Layout,
    children: [{
      path: '',
      meta: {
        title: "通知公告"
      },
      component: () =>
        import('@/views/notice/index.vue')
    },
    {
      path: '/notice/detail/:Id',
      meta: {
        title: "通知公告"
      },
      component: () =>
        import('@/views/notice/detail.vue')
    }
    ]
  },
  // 会员注册
  {
    path: '/member',
    component: Layout,
    children: [{
      path: '',
      meta: {
        title: "会员注册"
      },
      component: () =>
        import('@/views/member/index.vue')
    }
    ]
  },
    villageRouter, recruitment, partymember, wangge, pension, guanghe, fangyi, huayang, zhengxie, oldman, anLuoYun, Lycal, ylOrder
  ]
});

// 动画效果
router.beforeEach((to, from, next) => {
  console.log(to.path)
  console.log(from.path)

  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.path == from.path || ((to.path == '/' || to.path == '/serve' || to.path == '/center') && (from.path == '/' || from.path == '/serve' || from.path == '/login' || from.path == '/village'))) {
    store.commit('UPDATE_DIRECTION', "none")
  } else if (to.path.indexOf(from.path) != -1 || (from.name && to.name.indexOf(from.name) != -1)) {
    store.commit('UPDATE_DIRECTION', "forward")
  } else {
    store.commit('UPDATE_DIRECTION', "reverse")
  }
  next();
})

export default router